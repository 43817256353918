import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "terms-of-use"
    }}>{`Terms of Use`}</h1>
    <p>{`If you use Entur Widget or Travel link, you must include the following content in your website’s terms of use:`}</p>
    <p>{`Entur uses cookies and the tools PostHog and Firebase to collect information about your use of Entur Widget and Travel link. As the personal data controller, Entur AS decides which information is available for these tools.`}</p>
    <p>{`The analytics tools receive general web and app statistics like time, language and general device information. Your IP address may be used to approximate your position. In addition, Entur logs events such as your travel search input anonymously to improve their services.`}</p>
    <p>{`Entur uses two cookies of type `}{`_`}{`ga, one of type `}{`_`}{`gat and one `}{`_`}{`gid.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      